<template>
  <div class="mt-2 relative flex justify-center min-h-screen items-center">
    <form v-on:submit.prevent="register" class="w-540 max-w-full flex flex-col bg-white p-8 rounded-lg shadow border">
      <div class="head mb-10">
        <h1 class="font-bold text-3xl text-start mb-2">{{ $t('register.form_title') }}</h1>
        <p class="text-textHeaderColor text-start">
          {{ trial && trial.trial_weeks ? $t('register.form_subtitle', { weeks: trial.trial_weeks}) : $t('register.form_subtitle_no_trial') }}
        </p>
      </div>
      <div class="mb-6">
        <FieldWrapper label="auth.price" name="price" :errors="v$.registerForm.price.$errors">
          <multiselect
            v-bind:class="{error: v$.registerForm.price.$error}"
            v-model="registerForm.price"
            :options="$store.state.products"
            group-values="prices"
            group-label="name"
            :custom-label="priceLabel"
            :selectLabel="$t('select')"
            deselectLabel=""
            :selectedLabel="$t('selected')"
            :placeholder="$t('selectOption')"
            :allow-empty="false"
          >
          <template v-slot:noResult>
            {{ $t('NoOptionsMatching') }}
          </template>
          <template v-slot:noOptions>
            {{ $t('NoOptionsAvailable') }}
          </template>
        </multiselect>
        </FieldWrapper>
        <FieldWrapper label="auth.email" name="email" :errors="v$.registerForm.email.$errors">
          <input name="email" type="email" v-bind:class="{error: v$.registerForm.email.$error}" v-model="registerForm.email" class="focus:shadow" :placeholder="$t('auth.email_placeholder')" @blur="v$.registerForm.email.$touch"/>
        </FieldWrapper>
        <FieldWrapper label="company" name="company" :errors="v$.registerForm.companyName.$errors">
          <input name="company" type="text" v-bind:class="{error: v$.registerForm.companyName.$error}" v-model="registerForm.companyName" class="focus:shadow" :placeholder="$t('company_placeholder')" @blur="v$.registerForm.companyName.$touch"/>
        </FieldWrapper>
        <FieldWrapper label="register.howManyBranches" name="howManyBranches" :errors="v$.registerForm.howManyBranches.$errors">
          <input name="howManyBranches" type="number" :min="minimumBranchesNumber" v-bind:class="{error: v$.registerForm.howManyBranches.$error}" v-model="registerForm.howManyBranches" class="focus:shadow" @blur="v$.registerForm.howManyBranches.$touch"/>
        </FieldWrapper>
        <FieldWrapper label="auth.password" name="password" :errors="v$.registerForm.password.$errors" :hint="`${$t('register.password_length')} ${$t('register.password_not_strong')}`">
          <input name="password" type="password" v-bind:class="{error: v$.registerForm.password.$error}" v-model="registerForm.password" class="focus:shadow" :placeholder="$t('auth.password_placeholder')" @blur="v$.registerForm.password.$touch"/>
        </FieldWrapper>
        <FieldWrapper label="auth.password_confirm" name="passwordConfirm" :errors="v$.registerForm.passwordConfirm.$errors" :hint="`${$t('register.password_length')} ${$t('register.password_not_strong')}`">
          <input name="passwordConfirm" type="password" v-bind:class="{error: v$.registerForm.passwordConfirm.$error}" v-model="registerForm.passwordConfirm" class="focus:shadow" :placeholder="$t('auth.password_confirm_placeholder')" @blur="v$.registerForm.passwordConfirm.$touch"/>
        </FieldWrapper>
      </div>
      <div class="flex flex-col items-center space-y-3">
        <button type="submit" class="primary w-full h-11" :disabled="v$.registerForm.invalid">{{ $t('register.submit') }}</button>
      </div>
      <div class="flex items-center justify-center gap-x-2 mt-4">
        <div>{{ $t('register.account') }}</div>
        <router-link to="/auth" class="block text-primary font-bold hover:underline text-left">{{ $t('register.login') }}</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, sameAs, email, minValue } from '@/customValidators'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'

export default defineComponent({
  name: 'Register',
  setup: () => {
    const store = useStore()
    store.commit('setVuelidateExternalResults', { registerForm: { } })
    return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
  },
  data: function () {
    return {
      registerForm: {
        price: null,
        email: '',
        password: '',
        passwordConfirm: '',
        companyName: '',
        howManyBranches: 1,
        token: null
      },
      minimumBranchesNumber: 1
    }
  },
  validations: function () {
    return {
      registerForm: {
        price: { required },
        email: { required, email },
        password: { required },
        passwordConfirm: { required, sameAs: sameAs(this.registerForm.password) },
        companyName: { required },
        howManyBranches: { required, minValue: minValue(this.minimumBranchesNumber) }
      }
    }
  },
  components: {
    FieldWrapper
  },
  computed: {
    trial () {
      return this.$store.state.discounts ? this.$store.state.discounts.find((discount) => discount.trial_weeks && discount.trial_weeks > 0) : null
    }
  },
  methods: {
    priceLabel (price) {
      return `${price.unit_amount / 100} ${price.currency.toUpperCase()}${price.recurring && price.recurring.interval ? ' /' + this.$t(price.recurring.interval) : ''}/${this.$t('branch').toLowerCase()}`
    },
    async register () {
      const isFormCorrect = await this.v$.registerForm.$validate()
      if (!isFormCorrect) return
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')
      this.registerForm.token = token

      this.$store.dispatch('register', this.registerForm)
    }
  },
  beforeMount () {
    this.$store.dispatch('getProducts').then(() => {
      if (this.$route.query) {
        const priceId = this.$route.query.price
        this.$store.state.products.forEach(product => {
          const foundPrice = product.prices ? product.prices.find(searchedPrice => searchedPrice.id === priceId) : null
          this.registerForm.price = foundPrice || this.registerForm.price
        })
      }
    })
  }
})
</script>

<style lang="scss">
</style>
